import { splitStringByComma } from '@shared/utils/splitStringByComma';

export default defineNuxtRouteMiddleware(async (to) => {
  const params = to.params as {
    permalink: string;
  };

  const { data } = await useGetArticleDetailByPermalink(params.permalink, 100);

  const tags = splitStringByComma(data.value!.tags ?? '');

  const { setBreadcrumbs } = useBreadcrumbs();
  setBreadcrumbs([
    {
      to: '/public/article/news',
      text: 'ニュース',
    },
    ...(tags.length
      ? [
          {
            to: {
              path: '/public/article/news',
              query: {
                tag: tags[0],
              },
            },
            text: tags[0],
          },
        ]
      : []),
    {
      text: data.value!.title,
    },
  ]);
});
